
import { defineComponent, ref, watch } from 'vue';
import XEUtils from 'xe-utils';
export default defineComponent({
  props: ['modelValue', 'digits', 'min', 'max', 'placeholder', 'minus', 'disabled', 'customClass'],
  emits: ['update:modelValue', 'blur'],
  setup(props, ctx) {
    let showValue = ref('');
    const changeInput = (e) => {
      let reg: any = null;
      reg = new RegExp(`^(-)*(\\d+)\\.(\\d{0,${props.digits}}).*$`);
      let value = e;
      if (props.minus && props.minus === true) {
        value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
        value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
        value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        value = value.replace(/-{2,}/g, '-'); // 只保留第一个. 清除多余的
      } else {
        value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
        value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
        value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      }
      // 只能输入两个小数
      value = value.replace(reg, '$1$2.$3');

      if (Number(value) > props.max) {
        let len = value.length;
        value = value.substr(0, len - 1);
      }
      console.log(value);
      showValue.value = props.minus ? value : value;
      ctx.emit('update:modelValue', value);
      return value;
    };
    const changeClear = () => {
      ctx.emit('update:modelValue', null);
    };
    const changeBlur = (e) => {
      let value = e.target.value;
      if (value == '-') {
        value = '';
        showValue.value = '';
        ctx.emit('update:modelValue', value);
      } else {
        if (XEUtils.isNaN(Number(value))) {
          value = '';
          showValue.value = '';
          ctx.emit('update:modelValue', value);
        } else {
          if (Number(value) > props.max) {
            value = '';
            showValue.value = '';
          }
          ctx.emit('update:modelValue', value === null || value === '' ? value : Number(value));
        }
      }
      ctx.emit('blur', e);
    };
    watch(
      () => props.modelValue,
      () => {
        showValue.value = props.modelValue;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return { showValue, changeInput, changeBlur, changeClear };
  },
});
