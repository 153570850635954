import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-516e87d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "number-input" }
const _hoisted_2 = { class: "custom-append" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      class: _normalizeClass(_ctx.customClass),
      modelValue: _ctx.showValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showValue) = $event)),
      onInput: _ctx.changeInput,
      placeholder: _ctx.placeholder,
      onBlur: _ctx.changeBlur,
      clearable: "",
      onClear: _ctx.changeClear,
      disabled: _ctx.disabled
    }, null, 8, ["class", "modelValue", "onInput", "placeholder", "onBlur", "onClear", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}