import { erpConfirm, errorMessage } from '@/utils/publicMethods';
import { reactive, ref, toRefs } from 'vue';
type FormData = {
  settleType: string[]; // 计费方式
  isBag: boolean;
  isDay: boolean;
  contractNo: string | null;
  signDate: string | null;
  customerId: string | null;
  customerName: string | null;
  contractPrincipalId: string | null;
  PrincipalName: string | null;
  contractName: string | null;
  businessType: number | string | null;
  startDate: string | null;
  endDate: string | null;
  attachments: any[];
  remark: string | null;
  clearanceFeeLarge: number | null; // 大柜代理费
  clearanceFeeLargeUnit: number; // 大柜代理费单位
  clearanceFeeLargeMinWeight: number; // 大柜最低吨数
  clearanceFeeSmall: number; // 小柜代理费（元/柜）
  agencyFee: number; // 代采费
  agencyFeeUnit: number; // 代采费单位
  insuranceRate: number; // 保险费,
  multiProductFeeConfigList: any[];
  multiPickupFeeConfigList: any[];
  interestRateConfigList: any[];
  agencyFeeList: any[];
  cgFeeList: any[];
  halfQhdcServiceFee: number; // 半期货服务费
  halfQhdcServiceFeeUnit: number; // 半期货服务费单位
  multiVarietyFeeStartCount: number; // 开始收费的品种数
  multiVarietyFee: number; // 费用（元/品种）
  dayOtherFeeList: any[];
  bagOtherFeeList: any[];
  [propName: string]: any;
};
type defineGoodsNameType = {
  feeType: number;
  configMax: string | number | null;
  configMin: string | number | null;
  fee: string | number | null;
};
type defineFeeType = {
  configMax: string | number | null;
  configMin: string | number | null;
  fee: string | number | null;
};
type defineInterestFeeType = {
  configMax: string | number | null;
  configMin: string | number | null;
  rate: string | number | null;
  minUseDays: string | number | null;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const isClearable = ref(false);
  const formData: FormData = reactive({
    feeTip: '收费类型分为固定价和按品名收费两种。固定收费：在最小值和最大值范围内，价格不变。按品名收费：按单个品名的价格收费',
    settleType: [], // 计费方式
    isBag: false,
    isDay: false,
    contractNo: null,
    signDate: null,
    customerId: null,
    customerName: null,
    contractPrincipalId: null,
    principalName: null,
    contractName: null,
    businessType: 1,
    startDate: null,
    endDate: null,
    attachments: [],
    remark: null,
    clearanceFeeLarge: null, // 大柜代理费
    clearanceFeeLargeUnit: 1, // 大柜代理费单位
    clearanceFeeLargeMinWeight: 24, // 大柜最低吨数
    clearanceFeeSmall: null, // 小柜代理费（元/柜）
    agencyFee: null, // 代采费
    agencyFeeUnit: 1, // 代采费单位
    insuranceRate: null, // 保险费,
    multiProductFeeConfigList: [
      { feeType: 1, configMin: 1, configMax: 2, fee: 0 },
      { feeType: 1, configMin: 3, configMax: 7, fee: 500 },
      { feeType: 1, configMin: 8, configMax: 10, fee: 1000 },
      { feeType: 1, configMin: 11, configMax: 20, fee: 1500 },
      { feeType: 1, configMin: 21, configMax: null, fee: 1600 },
    ],
    multiPickupFeeConfigList: [
      { configMin: 1, configMax: 5, fee: 0 },
      { configMin: 6, configMax: 10, fee: 200 },
      { configMin: 11, configMax: null, fee: 500 },
    ],
    interestRateConfigList: [
      { configMin: 1, configMax: 45, rate: null, minUseDays: 15 },
      { configMin: 46, configMax: 90, rate: null, minUseDays: null },
      { configMin: 91, configMax: null, rate: null, minUseDays: null },
    ],
    agencyFeeList: [
      {
        category: 1,
        categoryDesc: '牛',
        clearanceFeeLarge: 350,
        clearanceFeeLargeUnit: 1,
        clearanceFeeSmallUnitDesc: '元/柜',
        clearanceFeeLargeMinWeight: 24,
        clearanceFeeSmall: 7200,
      },
      {
        category: 2,
        categoryDesc: '羊',
        clearanceFeeLarge: 350,
        clearanceFeeLargeUnit: 1,
        clearanceFeeSmallUnitDesc: '元/柜',
        clearanceFeeLargeMinWeight: 24,
        clearanceFeeSmall: 7200,
      },
      {
        category: 3,
        categoryDesc: '猪',
        clearanceFeeLarge: 350,
        clearanceFeeLargeUnit: 1,
        clearanceFeeSmallUnitDesc: '元/柜',
        clearanceFeeLargeMinWeight: 24,
        clearanceFeeSmall: 7200,
      },
      {
        category: 4,
        categoryDesc: '禽',
        clearanceFeeLarge: 350,
        clearanceFeeLargeUnit: 1,
        clearanceFeeSmallUnitDesc: '元/柜',
        clearanceFeeLargeMinWeight: 24,
        clearanceFeeSmall: 7200,
      },
      {
        category: 5,
        categoryDesc: '水产',
        clearanceFeeLarge: 350,
        clearanceFeeLargeUnit: 1,
        clearanceFeeSmallUnitDesc: '元/柜',
        clearanceFeeLargeMinWeight: 24,
        clearanceFeeSmall: 7200,
      },
    ],
    cgFeeList: [
      { category: 1, categoryDesc: '牛', agencyFee: 200, agencyFeeUnit: 1 },
      { category: 2, categoryDesc: '羊', agencyFee: 200, agencyFeeUnit: 1 },
      { category: 3, categoryDesc: '猪', agencyFee: 200, agencyFeeUnit: 1 },
      { category: 4, categoryDesc: '禽', agencyFee: 200, agencyFeeUnit: 1 },
      { category: 5, categoryDesc: '水产', agencyFee: 200, agencyFeeUnit: 1 },
    ],
    halfQhdcServiceFee: 10000, // 半期货服务费
    halfQhdcServiceFeeUnit: 1, // 半期货服务费单位
    multiVarietyFeeStartCount: 2, // 开始收费的品种数
    multiVarietyFee: 300, // 费用（元/品种）
    dayOtherFeeList: [
      { feeName: '操作费(垫资的情况下才收取此费用)', feeStandard: null, feeType: 1, id: null, settleType: 1, feeUnitDesc: '元/吨', feeUnit: 1 },
    ],
    bagOtherFeeList: [{ feeName: '其他服务费', feeStandard: null, feeType: 1, id: null, settleType: 2, feeUnitDesc: '元/柜', feeUnit: 2 }],
    ...params,
  });
  const principalNameOption = ref([]); //用来存放签约主体字段
  const customFormData = reactive({
    customerOption: [],
    fileUrlsArray: [], // 框架合同文件
    fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .doc, .xls, .xlsx, .zip',
    fileTips: '允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx,zip  大小上限：30M',
    shortArrays: [],
  });
  const rules = reactive({
    contractNo: [
      {
        required: true,
        message: '请输入合同编号',
        trigger: ['blur', 'change'],
      },
    ],
    customerName: [{ required: true, message: '请选择客户', trigger: ['blur', 'change'] }],
    principalName: [{ required: true, message: '请选择签约主体', trigger: ['blur', 'change'] }],
    businessType: [{ required: true, message: '请选业务类型', trigger: ['blur', 'change'] }],
    startDate: [
      {
        required: true,
        message: '请选择生效时间',
        trigger: ['blur', 'change'],
      },
    ],
    endDate: [
      {
        required: true,
        message: '请选择到期时间',
        trigger: ['blur', 'change'],
      },
    ],
    settleType: [
      {
        required: true,
        message: '请选择计费方式',
        trigger: ['blur', 'change'],
      },
    ],
    attachments: [
      {
        required: true,
        message: '请选择合同文件',
        trigger: ['blur', 'change'],
      },
    ],
    clearanceFeeLarge: [
      {
        required: true,
        message: '请输入大柜代理费',
        trigger: ['blur', 'change'],
      },
    ],
    clearanceFeeLargeMinWeight: [
      {
        required: true,
        message: '请输入大柜最低吨数',
        trigger: ['blur', 'change'],
      },
    ],
    clearanceFeeSmall: [
      {
        required: true,
        message: '请输入小柜代理费(元/柜)',
        trigger: ['blur', 'change'],
      },
    ],
    agencyFee: [{ required: true, message: '请输入代采费', trigger: ['blur', 'change'] }],
    insuranceRate: [
      {
        required: true,
        message: '请输入保险费率(‰)',
        trigger: ['blur', 'change'],
      },
    ],
    multiVarietyFeeStartCount: [
      {
        required: true,
        message: '请输入开始收费的品种数',
        trigger: ['blur', 'change'],
      },
    ],
    multiVarietyFee: [
      {
        required: true,
        message: '请输入费用(元/品种)',
        trigger: ['blur', 'change'],
      },
    ],
  });
  // 现货规则
  const spotRules = reactive({
    contractNo: [
      {
        required: true,
        message: '请输入合同编号',
        trigger: ['blur', 'change'],
      },
    ],
    customerName: [{ required: true, message: '请选择客户', trigger: ['blur', 'change'] }],
    principalName: [{ required: true, message: '请选择签约主体', trigger: ['blur', 'change'] }],
    businessType: [{ required: true, message: '请选业务类型', trigger: ['blur', 'change'] }],
    startDate: [
      {
        required: true,
        message: '请选择生效时间',
        trigger: ['blur', 'change'],
      },
    ],
    endDate: [
      {
        required: true,
        message: '请选择到期时间',
        trigger: ['blur', 'change'],
      },
    ],
    settleType: [
      {
        required: true,
        message: '请选择计费方式',
        trigger: ['blur', 'change'],
      },
    ],
    attachments: [
      {
        required: true,
        message: '请选择合同文件',
        trigger: ['blur', 'change'],
      },
    ],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };

  const valInteger = (val, prop) => {
    let value = val.target.value;
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/^0{2,}|[^\d]+/g, '');
    if (value.length >= 2) {
      value = parseInt(value);
    }
    formData[prop] = value;
  };
  // 校验代理费
  const valClearanceInteger = (val, prop, index) => {
    let value = val.target.value;
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/^0{2,}|[^\d]+/g, '');
    if (value.length >= 2) {
      value = parseInt(value);
    }
    formData.agencyFeeList[index][prop] = value;
  };
  // 校验代采费
  const valAgentInteger = (val, prop, index) => {
    let value = val.target.value;
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/^0{2,}|[^\d]+/g, '');
    if (value.length >= 2) {
      value = parseInt(value);
    }
    formData.cgFeeList[index][prop] = value;
  };
  const valThousandBit = (e, prop) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入两个小数
    value = value.replace(/^(\d)\.(\d\d).*$/, '$1.$2'); // eslint-disable-line
    if (value.indexOf('.') < 0 && value !== '') {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) == 0) {
        // eslint-disable-line

        value = value.substr(1);
      }
      // 如果数据是001这种类型，还需要再执行一次。
      if (value.substr(0, 1) == 0) {
        // eslint-disable-line

        value = value.substr(1);
      }
      value = String(parseInt(value));
    }
    if (value.indexOf('.') >= 0 && value !== '') {
      value = String(parseFloat(value).toFixed(2));
    }
    if (value.indexOf('.') === 0) {
      value = '0' + value.substr(0, 3);
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.replace('.', '');
    }
    if (value > 9.99) {
      value = 9.99;
    }
    if (parseFloat(value)) {
      formData[prop] = value;
    } else {
      formData[prop] = null;
    }
  };
  // 校验 多品名费用正整数
  const valLabelFeeInteger = (val, prop, index, haszero) => {
    let value = val.target.value;
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/^0{2,}|[^\d]+/g, '');
    if (value.length >= 2) {
      value = parseInt(value);
    }
    if (value == 0 && !haszero) {
      // eslint-disable-line

      value = null;
    }
    formData.multiProductFeeConfigList[index][prop] = value;
  };

  // 多品名费用添加
  const addLabelFee = () => {
    const len = formData.multiProductFeeConfigList.length;
    if (len >= 20) {
      return;
    }
    const fee: defineGoodsNameType = {
      feeType: 1,
      configMax: null,
      configMin: null,
      fee: null,
    };
    if (len === 0) {
      fee.configMin = 1;
    }
    formData.multiProductFeeConfigList.push(fee);
  };

  // 删除多品名费用
  const removeLabelFee = (index) => {
    const obj = formData.multiProductFeeConfigList[index];
    if (obj.configMin || obj.configMax || obj.fee) {
      erpConfirm('确定删除吗？').then(() => {
        formData.multiProductFeeConfigList.splice(index, 1);
        const len = formData.multiProductFeeConfigList.length;
        if (len > 0) {
          formData.multiProductFeeConfigList[len - 1].configMax = null;
        }
      });
    } else {
      formData.multiProductFeeConfigList.splice(index, 1);
      const len = formData.multiProductFeeConfigList.length;
      if (len > 0) {
        formData.multiProductFeeConfigList[len - 1].configMax = null;
      }
    }
  };

  const valVarietiest = (val, prop) => {
    let value = val.target.value;
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/^0{2,}|[^\d]+/g, '');
    if (value.length >= 2) {
      value = parseInt(value);
    }
    if (value == 0) {
      // eslint-disable-line
      value = null;
    }
    formData[prop] = value;
  };
  // 增加多次提货费
  const addPickupFee = () => {
    const len = formData.multiPickupFeeConfigList.length;
    if (len >= 20) {
      return;
    }
    const fee: defineFeeType = {
      configMax: null,
      configMin: null,
      fee: null,
    };
    if (len === 0) {
      fee.configMin = 1;
    }
    formData.multiPickupFeeConfigList.push(fee);
  };
  // 校验 多品名费用正整数
  const valDeliveryFeeInteger = (val, prop, index, haszero) => {
    let value = val.target.value;
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/^0{2,}|[^\d]+/g, '');
    if (value.length >= 2) {
      value = parseInt(value);
    }
    if (value == 0 && !haszero) {
      // eslint-disable-line
      value = null;
    }
    formData.multiPickupFeeConfigList[index][prop] = value;
  };
  // 删除多次提货费
  const removePickupFee = (index) => {
    const obj = formData.multiPickupFeeConfigList[index];
    if (obj.configMin || obj.configMax || obj.fee) {
      erpConfirm('确定删除吗？').then(() => {
        formData.multiPickupFeeConfigList.splice(index, 1);
        const len = formData.multiPickupFeeConfigList.length;
        if (len > 0) {
          formData.multiPickupFeeConfigList[len - 1].configMax = null;
        }
      });
    } else {
      formData.multiPickupFeeConfigList.splice(index, 1);
      const len = formData.multiPickupFeeConfigList.length;
      if (len > 0) {
        formData.multiPickupFeeConfigList[len - 1].configMax = null;
      }
    }
  };
  // 增加利率
  const addInterestRate = () => {
    const len = formData.interestRateConfigList.length;
    if (len >= 20) {
      return;
    }
    const fee: defineInterestFeeType = {
      configMax: null,
      configMin: null,
      rate: null,
      minUseDays: null,
    };
    if (len === 0) {
      fee.configMin = 1;
      fee.minUseDays = 15;
    }
    formData.interestRateConfigList.push(fee);
  };
  const valRateDayInteger = (val, prop, index, haszero) => {
    let value = val.target.value;
    value = value.replace(/[^\d]/g, '');
    value = value.replace(/^0{2,}|[^\d]+/g, '');
    if (value.length >= 2) {
      value = parseInt(value);
    }
    if (value == 0 && !haszero) {
      // eslint-disable-line

      value = null;
    }
    formData.interestRateConfigList[index][prop] = value;
  };

  const valRateFee = (e, prop, index) => {
    let value = e.target.value;
    value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    // 只能输入两个小数
    value = value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2'); // eslint-disable-line
    if (value.indexOf('.') < 0 && value !== '') {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (value.substr(0, 1) === 0) {
        value = value.substr(1);
      }
    }
    if (value.indexOf('.') === 0) {
      value = '0' + value.substr(0, 3);
    }
    if (value.lastIndexOf('.') === value.length - 1) {
      value = value.replace('.', '');
    }
    if (!isNaN(parseFloat(value))) {
      if (!~value.indexOf('.')) {
        value = parseFloat(value);
      } else {
        value = parseFloat(value).toFixed(2);
      }
    }
    if (value > 99.99) {
      value = 99.99;
    }
    formData.interestRateConfigList[index][prop] = value;
  };
  // 删除利率
  const removeInterestRate = (index) => {
    const obj = formData.interestRateConfigList[index];
    if (obj.configMin || obj.configMax || obj.rate || obj.minUseDays) {
      erpConfirm('确定删除吗？').then(() => {
        formData.interestRateConfigList.splice(index, 1);
        const len = formData.interestRateConfigList.length;
        if (len > 0) {
          formData.interestRateConfigList[len - 1].configMax = null;
        }
      });
    } else {
      formData.interestRateConfigList.splice(index, 1);
      const len = formData.interestRateConfigList.length;
      if (len > 0) {
        formData.interestRateConfigList[len - 1].configMax = null;
      }
    }
  };
  const validMultiFee = () => {
    return new Promise((resolve, reject) => {
      const interestRateConfigListLen = formData.interestRateConfigList.length;
      // let result = true;
      if (formData.clearanceFeeLargeUnit !== 2 && !formData.clearanceFeeLargeMinWeight) {
        // result = false;
        // errorMessage('请填写所有带星号的必填项');
        reject('请填写所有带星号的必填项');
      }
      if (formData.multiProductFeeConfigList.length === 0 || formData.multiPickupFeeConfigList.length === 0 || formData.interestRateConfigList.length === 0) {
        // result = false;
        // errorMessage('请填写所有带星号的必填项');
        reject('请填写所有带星号的必填项');
      }
      if (formData.interestRateConfigList.length > 0 && !formData.interestRateConfigList[0].minUseDays) {
        // result = false;
        // errorMessage('请填写所有带星号的必填项');
        reject('请填写所有带星号的必填项');
      }
      for (let i = 0; i < interestRateConfigListLen; i++) {
        if (formData.interestRateConfigList[i].rate === null || formData.interestRateConfigList[i].rate === '') {
          // result = false;
          // errorMessage('请填写所有带星号的必填项');
          reject('请填写所有带星号的必填项');
        }
      }
      // 校验多品名费用 费用
      for (let i = 0; i < formData.multiProductFeeConfigList.length; i++) {
        if (formData.multiProductFeeConfigList[i].fee === '' || formData.multiProductFeeConfigList[i].fee === null) {
          // result = false;
          // errorMessage('请填写所有带星号的必填项');
          reject('请填写所有带星号的必填项');
        }
      }
      // 校验多次提货费 费用
      for (let i = 0; i < formData.multiPickupFeeConfigList.length; i++) {
        if (formData.multiPickupFeeConfigList[i].fee === '' || formData.multiPickupFeeConfigList[i].fee === null) {
          // result = false;
          // errorMessage('请填写所有带星号的必填项');
          reject('请填写所有带星号的必填项');
        }
      }

      // 校验利率 利率
      for (let i = 0; i < formData.interestRateConfigList.length; i++) {
        if (formData.interestRateConfigList[i].rate === '' || formData.interestRateConfigList[i].rate === null) {
          // result = false;
          // errorMessage('请填写所有带星号的必填项');
          reject('请填写所有带星号的必填项');
        }
      }
      // 校验多品名费用
      const multiProductFeeConfigListLen = formData.multiProductFeeConfigList.length;
      if (multiProductFeeConfigListLen > 1) {
        // 如果收费标准有多行，第一行最小值必须是1
        if (Number(formData.multiProductFeeConfigList[0].configMin) !== 1) {
          // result = false;
          // errorMessage('多品名费用' + '第一行的最小值必须是1');
          reject('多品名费用第一行的最小值必须是1');
        }
        for (let i = 1; i < multiProductFeeConfigListLen; i++) {
          if (formData.multiProductFeeConfigList[i].configMin - 1 !== Number(formData.multiProductFeeConfigList[i - 1].configMax)) {
            // result = false;
            // errorMessage('多品名费用' + '每行的最小值必须是上一行最大值加1');
            reject('多品名费用每行的最小值必须是上一行最大值加1');
          }
        }
        for (let i = 0; i < multiProductFeeConfigListLen - 1; i++) {
          if (formData.multiProductFeeConfigList[i].configMin >= Number(formData.multiProductFeeConfigList[i].configMax)) {
            // result = false;
            // errorMessage('多品名费用' + '每行的最大值必须大于最小值');
            reject('多品名费用每行的最大值必须大于最小值');
          }
        }
      }
      if (multiProductFeeConfigListLen === 1) {
        if (Number(formData.multiProductFeeConfigList[0].configMin) !== 1) {
          // result = false;
          // errorMessage('多品名费用' + '第一行的最小值必须是1');
          reject('多品名费用第一行的最小值必须是1');
        }
      }
      // 校验多次提货费
      const multiPickupFeeConfigListLen = formData.multiPickupFeeConfigList.length;
      if (multiPickupFeeConfigListLen > 1) {
        // 如果收费标准有多行，第一行最小值必须是1
        if (Number(formData.multiPickupFeeConfigList[0].configMin) !== 1) {
          // result = false;
          // errorMessage('多次提货费' + '第一行的最小值必须是1');
          reject('多次提货费第一行的最小值必须是1');
        }
        for (let i = 1; i < multiPickupFeeConfigListLen; i++) {
          if (formData.multiPickupFeeConfigList[i].configMin - 1 !== Number(formData.multiPickupFeeConfigList[i - 1].configMax)) {
            // result = false;
            // errorMessage('多次提货费' + '每行的最小值必须是上一行最大值加1');
            reject('多次提货费每行的最小值必须是上一行最大值加1');
          }
        }
        for (let i = 0; i < multiPickupFeeConfigListLen - 1; i++) {
          if (formData.multiPickupFeeConfigList[i].configMin >= Number(formData.multiPickupFeeConfigList[i].configMax)) {
            // result = false;
            // errorMessage('多次提货费' + '每行的最大值必须大于最小值');
            reject('多次提货费每行的最大值必须大于最小值');
          }
        }
      }
      if (multiPickupFeeConfigListLen === 1) {
        if (Number(formData.multiPickupFeeConfigList[0].configMin) !== 1) {
          // result = false;
          // errorMessage('多次提货费' + '第一行的最小值必须是1');
          reject('多次提货费第一行的最小值必须是1');
        }
      }
      // 校验利率
      if (interestRateConfigListLen > 1) {
        // 如果收费标准有多行，第一行最小值必须是1
        if (Number(formData.interestRateConfigList[0].configMin) !== 1) {
          // result = false;
          // errorMessage('利率' + '第一行的最小值必须是1');
          reject('利率第一行的最小值必须是1');
        }
        for (let i = 1; i < interestRateConfigListLen; i++) {
          if (formData.interestRateConfigList[i].configMin - 1 !== Number(formData.interestRateConfigList[i - 1].configMax)) {
            // errorMessage('利率'+'每行的最小值必须是上一行最大值加1');
            // result = false;
            reject('利率每行的最小值必须是上一行最大值加1');
          }
        }
        for (let i = 0; i < interestRateConfigListLen - 1; i++) {
          if (formData.interestRateConfigList[i].configMin >= Number(formData.interestRateConfigList[i].configMax)) {
            // result = false;
            // errorMessage('利率' + '每行的最大值必须大于最小值');
            reject('利率每行的最大值必须大于最小值');
          }
        }
      }
      if (interestRateConfigListLen === 1) {
        if (Number(formData.interestRateConfigList[0].configMin) !== 1) {
          // result = false;
          // errorMessage('利率' + '第一行的最小值必须是1');
          reject('利率第一行的最小值必须是1');
        }
      }
      if (interestRateConfigListLen === 2) {
        if (formData.interestRateConfigList[1].configMin - 1 !== Number(formData.interestRateConfigList[0].configMax)) {
          // result = false;
          // errorMessage('利率' + '每行的最小值必须是上一行最大值加1');
          reject('利率每行的最小值必须是上一行最大值加1');
        }
      }
      resolve(true);
    });
  };
  const handleFormData = (res): void => {
    const keys = Object.keys(res);
    const judgeKeys = ['interestRateConfigList', 'multiPickupFeeConfigList', 'multiProductFeeConfigList'];
    for (let i = 0; i < keys.length; i++) {
      if (!judgeKeys.includes(keys[i])) {
        if (keys[i] === 'agencyFeeList' && res[keys[i]] && res[keys[i]].length === 0) {
          console.log('no');
        } else if (keys[i] === 'cgFeeList' && res[keys[i]] && res[keys[i]].length === 0) {
          console.log('no');
        } else {
          formData[keys[i]] = res[keys[i]];
        }
      }
    }
    if (res.interestRateConfigList) {
      formData.interestRateConfigList = res.interestRateConfigList;
    }
    if (res.multiPickupFeeConfigList) {
      formData.multiPickupFeeConfigList = res.multiPickupFeeConfigList;
    }
    if (res.multiProductFeeConfigList) {
      formData.multiProductFeeConfigList = res.multiProductFeeConfigList;
    }
    formData.settleType = [];
    if (res.isDay) {
      formData.settleType.push('按天计费');
      if (formData.dayOtherFeeList.length === 0) {
        formData.dayOtherFeeList.push({
          feeName: '操作费(垫资的情况下才收取此费用)',
          feeStandard: null,
          feeType: 1,
          id: null,
          settleType: 1,
        });
      }
    }
    if (res.isBag) {
      formData.settleType.push('包干价');
      if (formData.bagOtherFeeList.length === 0) {
        formData.bagOtherFeeList.push({
          feeName: '其他服务费',
          feeStandard: null,
          feeType: 1,
          id: null,
          settleType: 2,
        });
      }
    }
  };

  return {
    rules,
    spotRules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    principalNameOption,
    valInteger,
    valClearanceInteger,
    valAgentInteger,
    isClearable,
    valThousandBit,
    valLabelFeeInteger,
    addLabelFee,
    removeLabelFee,
    valVarietiest,
    addPickupFee,
    valDeliveryFeeInteger,
    removePickupFee,
    addInterestRate,
    valRateDayInteger,
    valRateFee,
    removeInterestRate,
    validMultiFee,
    handleFormData,
  };
};
