
import { httpGet, httpPost } from '@/api';
import FileContent from '@/components/FileContent/FileContent.vue';
import NumberInput from '@/components/NumberInput/NumberInput.vue';
import { ajaxLoading, erpConfirm, errorMessage, formatTime, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent, computed, ref, nextTick } from 'vue';
import useFormData from './useContractFormData';
export default defineComponent({
  components: { FileContent, NumberInput },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};
    if (props.formType === 2) {
      // params = Object.assign({ operateType: 2 }, props.data);
    }
    const {
      rules,
      spotRules,
      formData,
      formRef,
      setFormData,
      fileUrlsArray,
      customerOption,
      principalNameOption,
      valInteger,
      valClearanceInteger,
      valAgentInteger,
      isClearable,
      valThousandBit,
      valLabelFeeInteger,
      addLabelFee,
      removeLabelFee,
      valVarietiest,
      addPickupFee,
      valDeliveryFeeInteger,
      removePickupFee,
      addInterestRate,
      valRateDayInteger,
      valRateFee,
      removeInterestRate,
      validMultiFee,
      handleFormData,
    } = useFormData(params);
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.attachments = [];
        fileList.forEach((item) => {
          formData.attachments.push({
            customerBaseFileName: item.name,
            customerBaseFileUrl: item.url,
          });
        });
      } else {
        formData.attachments = [];
      }
      formRef.value.validateField(['attachments']);
    };
    const isBaogan = computed(() => {
      if (formData.settleType && formData.settleType.includes('包干价')) {
        return true;
      } else {
        return false;
      }
    });
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    const isDayFee = computed(() => {
      if (formData.settleType && formData.settleType.includes('按天计费')) {
        return true;
      } else {
        return false;
      }
    });
    let validRules = ref({});
    validRules.value = rules;
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const initFile = () => {
      if (formData.attachments) {
        fileUrlsArray.value = [];
        formData.attachments.forEach((item) => {
          if (item.customerBaseFileName && item.customerBaseFileUrl) {
            (fileUrlsArray.value as any).push({
              name: item.customerBaseFileName,
              url: item.customerBaseFileUrl,
            });
          }
        });
      }
    };
    const submitData = async () => {
      let params = {};
      if (formData.isDay) {
        params = Object.assign({}, formData);
      }
      if (!formData.isDay && formData.isBag) {
        (params as any).contractNo = formData.contractNo;
        (params as any).signDate = formData.signDate;
        (params as any).customerName = formData.customerName;
        (params as any).customerId = formData.customerId;
        (params as any).principalName = formData.principalName;
        (params as any).contractPrincipalId = formData.contractPrincipalId;
        (params as any).id = formData.id;
        (params as any).version = formData.version;
        (params as any).businessType = formData.businessType;
        (params as any).startDate = formData.startDate;
        (params as any).endDate = formData.endDate;
        (params as any).isBag = formData.isBag;
        (params as any).isDay = formData.isDay;
        (params as any).attachments = formData.attachments;
        (params as any).remark = formData.remark;
      }
      const paramDayArr = formData.dayOtherFeeList.filter((item) => {
        return item.feeName && item.feeStandard;
      });
      (params as any).dayOtherFeeList = paramDayArr;

      const paramOtherArr = formData.bagOtherFeeList.filter((item) => {
        return item.feeName && item.feeStandard;
      });
      (params as any).bagOtherFeeList = paramOtherArr;
      ajaxLoading.lock();
      console.log('params', params);
      const res = await httpPost('malicrm/customer/saveCustomerContract', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        if (props.formType === 1) {
          successMessage('保存成功', 3000);
        }
        if (props.formType === 2) {
          successMessage('编辑成功', 3000);
        }
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          formData.signDate = formatTime(formData.signDate, 'YYYY-MM-DD');
          formData.startDate = formatTime(formData.startDate, 'YYYY-MM-DD');
          formData.endDate = formatTime(formData.endDate, 'YYYY-MM-DD');
          if (isDayFee.value && formData.businessType === 1) {
            validMultiFee()
              .then((res) => {
                if (res) {
                  submitData();
                }
              })
              .catch((err) => {
                errorMessage(err);
              });
          } else {
            submitData();
          }
        } else {
          errorMessage('请填写所有带星号的必填项');
        }
      });
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`/malicrm/customer/getCustomerContractById/${id}`);
      if (res.code === 200) {
        console.log('res.result', res.result);
        handleFormData(res.result);
        console.log('see', formData);
        initFile();
      } else {
        errorMessage(res.message);
      }
    };
    // const initPrincipalName = () => {};
    // 获取全部签约主体
    const getPrincipalName = async () => {
      const res = await httpGet('/malicrm/principal/noAuth/selectCrmContractPrincipal');

      if (res.code === 200) {
        principalNameOption.value = res.result;
      } else {
        errorMessage(res.message);
      }
    };
    const getContractPrincipalId = (val) => {
      principalNameOption.value.forEach((item) => {
        if ((item as any).contractPrincipalId === val) {
          formData.principalName = (item as any).principalName;
          formData.contractPrincipalId = (item as any).contractPrincipalId;
        }
      });
    };
    const getCustomerOption = async (name: string) => {
      const params = {
        customerName: name,
      };
      const res = await httpPost('/malicrm/business/selectCustomer', params);
      if (res.code === 200) {
        customerOption.value = res.result.map((item) => {
          return { customerName: item.customerName, id: item.id };
        });
      } else {
        errorMessage(res.message);
      }
    };
    const initCustomerOption = () => {
      // 失去焦点时重置筛选条件
      if (customerOption.value && customerOption.value.length <= 0) {
        getCustomerOption('');
      }
    };
    const getCustomerId = (val) => {
      customerOption.value.forEach((item) => {
        if ((item as any).id === val) {
          formData.customerName = (item as any).customerName;
          formData.customerId = (item as any).id;
        }
      });
    };
    // 增加其他费用
    const addOtherFee = (type: string): void => {
      let prop;
      const fee = {
        feeName: '操作费（垫资的情况下才收取此费用）',
        feeStandard: null,
        feeType: 1, // 1.其他费用 2.计费标准
        id: null,
        settleType: 1, // 1.按天计费 2.包干价
        feeUnitDesc: '元/吨',
        feeUnit: 1,
      };
      if (type === 'byDay') {
        prop = 'dayOtherFeeList';
      }
      if (type === 'baoGan') {
        prop = 'bagOtherFeeList';
        fee.feeName = '其他服务费';
        fee.settleType = 2;
        fee.feeUnit = 2;
        fee.feeUnitDesc = '元/柜';
      }
      const len = formData[prop].length;
      if (len >= 5) {
        return;
      }
      formData[prop].push(fee);
    };
    // 大柜代理费单位选择事件
    const agencyFeeUnitChange = (val, index) => {
      formData.agencyFeeList[index].clearanceFeeLargeUnit = val;
      if (val === 2) {
        formData.agencyFeeList[index].clearanceFeeLargeMinWeight = null;
      } else {
        formData.agencyFeeList[index].clearanceFeeLargeMinWeight = 24;
      }
      console.log('formData.agencyFeeList[index] after', formData.agencyFeeList[index]);
    };
    // 删除其他费用
    const removeOtherFeeByday = (index: number, type: string): void => {
      let prop;
      if (type === 'byDay') {
        prop = 'dayOtherFeeList';
      }
      if (type === 'baoGan') {
        prop = 'bagOtherFeeList';
      }
      const obj = formData[prop][index];
      if (obj.feeName || obj.feeStandard) {
        erpConfirm('确定删除吗？').then(() => {
          formData[prop].splice(index, 1);
        });
      } else {
        formData[prop].splice(index, 1);
      }
    };
    const settleTypeChange = (e) => {
      formData.settleType = e;
      formRef.value.validateField('settleType');
      if (e.includes('按天计费')) {
        formData.isDay = true;
        if (!formData.clearanceFeeLargeUnit) {
          formData.clearanceFeeLargeUnit = 1;
          formData.clearanceFeeLargeMinWeight = 24;
        }
        if (!formData.agencyFeeUnit) {
          formData.agencyFeeUnit = 1;
        }
        if (!formData.multiVarietyFeeStartCount) {
          formData.multiVarietyFeeStartCount = 2;
        }
        if (!formData.multiVarietyFee) {
          formData.multiVarietyFee = 300;
        }
        if (formData.dayOtherFeeList && formData.dayOtherFeeList.length === 0) {
          formData.dayOtherFeeList = [
            {
              feeName: '操作费（垫资的情况下才收取此费用）',
              feeStandard: null,
              feeType: 1,
              id: null,
              settleType: 1,
              feeUnit: 1,
              feeUnitDesc: '元/吨',
            },
          ];
        }
      } else {
        formData.isDay = false;
        formData.dayOtherFeeList = [
          {
            feeName: '操作费（垫资的情况下才收取此费用）',
            feeStandard: null,
            feeType: 1,
            id: null,
            settleType: 1,
            feeUnit: 1,
            feeUnitDesc: '元/吨',
          },
        ];
      }
      if (e.includes('包干价')) {
        formData.isBag = true;
        if (formData.bagOtherFeeList && formData.bagOtherFeeList.length === 0) {
          formData.bagOtherFeeList = [
            {
              feeName: '其他服务费',
              feeStandard: null,
              feeType: 1,
              id: null,
              settleType: 2,
              feeUnit: 2,
              feeUnitDesc: '元/柜',
            },
          ];
        }
      } else {
        formData.isBag = false;
        formData.bagOtherFeeList = [
          {
            feeName: '其他服务费',
            feeStandard: null,
            feeType: 1,
            id: null,
            settleType: 2,
            feeUnit: 2,
            feeUnitDesc: '元/柜',
          },
        ];
      }
    };
    // 现货、期货表单规则
    const businessTypeChange = (val) => {
      if (val === 1) {
        validRules.value = rules;
      } else {
        validRules.value = spotRules;
      }
      nextTick(() => {
        formRef.value.clearValidate();
      });
    };
    getPrincipalName();
    getCustomerOption('');
    if (props.formType > 1) {
      const data = (props.data as any).value;
      const id = data.id;
      initData(id);
    }
    console.log('formData', formData);
    return {
      businessTypeChange,
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      spotRules,
      validRules,
      formRef,
      setTitleLabel,
      getFileUrls,
      fileUrlsArray,
      initFile,
      initData,
      readOnly,
      getCustomerOption,
      initCustomerOption,
      getCustomerId,
      customerOption,
      principalNameOption,
      getContractPrincipalId,
      settleTypeChange,
      isBaogan,
      isDayFee,
      addOtherFee,
      removeOtherFeeByday,
      valInteger,
      valClearanceInteger,
      agencyFeeUnitChange,
      valAgentInteger,
      isClearable,
      valThousandBit,
      valLabelFeeInteger,
      addLabelFee,
      removeLabelFee,
      valVarietiest,
      addPickupFee,
      valDeliveryFeeInteger,
      removePickupFee,
      addInterestRate,
      valRateDayInteger,
      valRateFee,
      removeInterestRate,
      validMultiFee,
      handleFormData,
    };
  },
});
