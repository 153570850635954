import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, successMessage, errorMessage, showFiles } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'contractNo',
      value: '',
      placeholder: '请输入客户框架合同编号',
      width: 200,
    },
    {
      type: 'input',
      prop: 'clientManager',
      value: '',
      placeholder: '请输入业务负责人',
    },
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入所属客户',
    },
    // {
    //   type: 'multiSelect',
    //   prop: 'principalName',
    //   value: [],
    //   options: [
    //     {
    //       label: '深圳马力',
    //       value: 1,
    //     },
    //     {
    //       label: '上海马力',
    //       value: 2,
    //     },
    //   ],
    //   placeholder: '请选择签约主体',
    //   width: 180,
    // },
    {
      type: 'multiSelect',
      prop: 'settleTypes',
      value: [],
      options: [
        {
          label: '按天计费',
          value: 1,
        },
        {
          label: '包干价',
          value: 2,
        },
      ],
      placeholder: '请选择计费方式',
      width: 180,
    },
    {
      type: 'signSelect',
      prop: 'businessType',
      value: '',
      options: 'contract_business_type',
      placeholder: '请选择业务类型',
    },
    {
      type: 'input',
      prop: 'name',
      value: '',
      placeholder: '请输入文件名',
    },
    {
      type: 'time',
      prop: ['endStartDate', 'endEndDate'],
      value: [],
      placeholder: ['到期开始时间', '到期结束时间'],
    },
    {
      type: 'input',
      prop: 'creator',
      value: '',
      placeholder: '请输入创建人',
    },
    {
      type: 'time',
      prop: ['createdStartTime', 'createdEndTime'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '客户框架合同编号',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.contractNo}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 查看 */
          const clickFn = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(3);
            Instance.setupState.showVisible();
          };
          return { clickFn };
        },
      }),
      prop: 'contractNo',
      minWidth: 180,
    },
    {
      label: '业务负责人',
      prop: 'clientManager',
      minWidth: 200,
    },
    {
      label: '所属客户',
      prop: 'customerName',
      minWidth: 200,
    },
    {
      label: '签约主体',
      prop: 'principalName',
      minWidth: 180,
    },
    {
      label: '计费方式',
      prop: 'settleTypeDesc',
      minWidth: 160,
    },
    {
      label: '业务类型',
      prop: 'businessTypeDisplay',
      minWidth: 106,
    },
    {
      label: '文件名',
      component: defineComponent({
        template: `
          <div class="crm-cell-link" @click="clickFn(row)">
          {{row.name}}
          </div>
        `,
        props: ['row'],
        setup() {
          /** 查看 */
          const clickFn = (row) => {
            showFiles(row.name, row.url);
          };
          return { clickFn };
        },
      }),
      prop: 'contractNo',
      minWidth: 224,
    },
    {
      label: '到期时间',
      prop: 'endDate',
      component: defineComponent({
        template: `
          <div  :style="getFontColor(row)">
              {{row.endDate}}
          </div>
        `,
        props: ['row'],
        setup() {
          const getFontColor = (row) => {
            const res = '';
            if (row.markRed === true) {
              return 'color:#E93939';
            }
            return res;
          };
          return { getFontColor };
        },
      }),
      minWidth: 121,
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 116,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 180,
    },
    {
      label: '更新人',
      prop: 'lastOperator',
      minWidth: 116,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button v-if="hasPermission('crm:customer:saveCustomerContract')" type="primary" plain @click="edit(row)">编辑</el-button>
            <el-button v-if="hasPermission('crm:customer:deleteCustomerContract')" type="info" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定删除吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/customer/deleteCustomerContract', {
                  id: row.id,
                  version: row.version,
                });
                if (res.code === 200) {
                  successMessage('删除成功');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      width: 200,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
